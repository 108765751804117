const defaultLayout = {
  // Overall text styling
  font: {
    family: 'Open Sans, Arial, sans-serif',
    size: 16,
    color: '#e5e5e5' // Light text on dark background
  },

  // Page and plot backgrounds
  paper_bgcolor: '#363a3d', // Off-black background (unchanged)
  plot_bgcolor: '#363a3d',

  // No legend by default
  showlegend: false,
  hovermode: 'x unified', // Keeps hover behavior

  // Title styling
  title: {
    text: '',
    font: {
      size: 20,
      color: '#e5e5e5'
    },
    x: 0.05 // Slightly left-aligned
  },

  // Muted color palette for data traces
  colorway: reportColorPalette,

  // X-axis styling
  xaxis: {
    showgrid: false,
    zeroline: false,
    showline: true,
    linecolor: '#444',  // Subtle axis line
    title: {
      text: '',
      font: {
        size: 16,
        color: '#e5e5e5'
      }
    },
    tickcolor: '#7d7d7d',
    ticklen: 6
  },

  // Y-axis styling
  yaxis: {
    showgrid: false,
    zeroline: false,
    showline: true,
    linecolor: '#444',
    title: {
      text: '',
      font: {
        size: 16,
        color: '#e5e5e5'
      }
    },
    tickcolor: '#7d7d7d',
    ticklen: 6
  },

  // Legend styling
  legend: {
    font: {
      size: 14,
      color: '#e5e5e5'
    },
    bgcolor: 'rgba(0,0,0,0)' // Transparent
  },

  // Margin adjustments
  margin: {
    l: 60,
    r: 30,
    t: 60,
    b: 50
  }
};

export const reportColorPalette = [
  '#4ed1c3',
  '#cc2da0',
  '#47cb4f',
  '#9536b0',
  '#6dc744',
  '#7450cd',
  '#9ec535',
  '#c35ad8',
  '#4f9b1e',
  '#a06ae7',
  '#3cad46',
  '#f27ff5',
  '#44cf77',
  '#dc6bdf',
  '#8ac251',
  '#325dd1',
  '#b7c13a',
  '#3c4dbb',
  '#c2ab27',
  '#5c7af1',
  '#e99929',
  '#774eb8',
  '#88a12e',
  '#554baa',
  '#cebb52',
  '#666ac8',
  '#72bd67',
  '#ea63c7',
  '#36a25a',
  '#e83889',
  '#2f7f2d',
  '#c07ce4',
  '#537e24',
  '#9c409f',
  '#51c591',
  '#bb2c74',
  '#a2c474',
  '#b64095',
  '#83a95f',
  '#ea88e2',
  '#445a06',
  '#9889e3',
  '#767c16',
  '#884b9f',
  '#ac8925',
  '#4f8de0',
  '#d5471d',
  '#3bc4e0',
  '#d73440',
  '#29a28f',
  '#db3963',
  '#44976e',
  '#f26246',
  '#226ab1',
  '#e77a3f',
  '#2d5192',
  '#e0a54c',
  '#494b93',
  '#c27323',
  '#494b93',
  '#a39e4a',
  '#7155a7',
  '#5e7222',
  '#f07cbe',
  '#347f4e',
  '#de6096',
  '#4b7d3c',
  '#c570bb',
  '#296437',
  '#d296da',
  '#545608',
  '#6462ad',
  '#836d19',
  '#494b93',
  '#cfae68',
  '#494b93',
  '#9b6f21',
  '#494b93',
  '#b34321',
  '#6aade4',
  '#9c541d',
  '#2e90bd',
  '#b34140',
  '#37a6a8',
  '#e46f68',
  '#1a6447',
  '#a53c63',
  '#7bbd94',
  '#494b93',
  '#6e6e23',
  '#494b93',
  '#bab77a',
  '#494b93',
  '#828f4c',
  '#764e90',
  '#415a1f',
  '#a8a6e8',
  '#695711',
  '#4b69ad',
  '#f0a276',
  '#4a5589',
  '#ddb182',
  '#72619d',
  '#597236',
  '#975d97',
  '#525620',
  '#8884be',
  '#704b0c',
  '#5e72a5',
  '#845d1f',
  '#d086b1',
  '#2f7b63',
  '#d1647a',
  '#6b6e37',
  '#ec8ba5',
  '#6d5d2c',
  '#9a4f7a',
  '#997e43',
  '#7f4569',
  '#c28f5b',
  '#8b4a5f',
  '#c87b52',
  '#98464b',
  '#896338',
  '#e99590',
  '#814a28',
  '#bb6f6b',
  '#9c5538'
]
export default defaultLayout

export const defaultTraceParameters = {
  hovertemplate: '%{y}',
  text: ['', '', ''],
  textposition: 'auto'
}
