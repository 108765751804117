import moment from 'moment'
import { reportColorPalette } from '@/components/helpers/plotly_style'

export default class ReportHelper {
  static dateModeFunctions = {
    today: this._dateFuncToday,
    yesterday: this._dateFuncYesterday,
    ytd: this._dateFuncYTD,
    mtd: this._dateFuncMTD,
    wtd: this._dateFuncWTD,
    'last-seven': this._dateFuncLastSeven,
    'last-week': this._dateFuncLastWeek,
    'last-month': this._dateFuncLastMonth,
    'last-year': this._dateFuncLastYear,
    'between-dates': this._dateFuncBetween
  }

  static _datesToQuery(startDate, endDate) {
    return {
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD')
    }
  }

  static _dateFuncToday(startDateType, startDateValue, endDateType, endDateValue) {
    return ReportHelper._datesToQuery(
      moment(new Date()),
      moment(new Date())
    )
  }

  static _dateFuncYesterday(startDateType, startDateValue, endDateType, endDateValue) {
    return ReportHelper._datesToQuery(
      moment(new Date()).subtract(1, 'day'),
      moment(new Date()).subtract(1, 'day')
    )
  }

  static _dateFuncYTD(startDateType, startDateValue, endDateType, endDateValue) {
    return ReportHelper._datesToQuery(
      moment(new Date()).startOf('year'),
      moment(new Date())
    )
  }

  static _dateFuncMTD(startDateType, startDateValue, endDateType, endDateValue) {
    return ReportHelper._datesToQuery(
      moment(new Date()).startOf('month'),
      moment(new Date())
    )
  }

  static _dateFuncWTD(startDateType, startDateValue, endDateType, endDateValue) {
    return ReportHelper._datesToQuery(
      moment(new Date()).startOf('week'),
      moment(new Date())
    )
  }

  static _dateFuncLastSeven(startDateType, startDateValue, endDateType, endDateValue) {
    return ReportHelper._datesToQuery(
      moment(new Date()).subtract(8, 'days'),
      moment(new Date()).subtract(1, 'days')
    )
  }

  static _dateFuncLastWeek(startDateType, startDateValue, endDateType, endDateValue) {
    return ReportHelper._datesToQuery(
      moment(new Date()).subtract(1, 'week').startOf('week'),
      moment(new Date()).subtract(1, 'week').endOf('week')
    )
  }

  static _dateFuncLastMonth(startDateType, startDateValue, endDateType, endDateValue) {
    return ReportHelper._datesToQuery(
      moment(new Date()).subtract(1, 'month').startOf('month'),
      moment(new Date()).subtract(1, 'month').endOf('month')
    )
  }

  static _dateFuncLastYear(startDateType, startDateValue, endDateType, endDateValue) {
    console.log('Previous Year!')
    console.log(moment(new Date()).subtract(1, 'year').startOf('year'))
    console.log(moment(new Date()).subtract(1, 'year').endOf('year'))
    return ReportHelper._datesToQuery(
      moment(new Date()).subtract(1, 'year').startOf('year'),
      moment(new Date()).subtract(1, 'year').endOf('year')
    )
  }

  static dateTypeFunctions = {
    today: () => moment(new Date()),
    yesterday: () => moment(new Date()).subtract(1, 'day'),
    'start-of-week': () => moment(new Date()).startOf('week'),
    'end-of-week': () => moment(new Date()).endOf('week'),
    'relative-past-days': (x) => moment(new Date()).subtract(x, 'day'),
    'fixed-date': (x) => moment(x)
  }

  static _dateFuncBetween(startDateType, startDateValue, endDateType, endDateValue) {
    let startDate, endDate
    if (Object.hasOwn(ReportHelper.dateTypeFunctions, startDateType)) {
      startDate = ReportHelper.dateTypeFunctions[startDateType](startDateValue)
    } else {
      console.error(`Report Date Parsing Error: Unknown Start Date Type ${startDateType}!`)
      throw new Error('Unknown Start Date Type')
    }

    if (Object.hasOwn(ReportHelper.dateTypeFunctions, endDateType)) {
      endDate = ReportHelper.dateTypeFunctions[endDateType](endDateValue)
    } else {
      console.error(`Report Date Parsing Error: Unknown End Date Type ${endDateType}!`)
      throw new Error('Unknown End Date Type')
    }
    return ReportHelper._datesToQuery(
      startDate,
      endDate
    )
  }

  static getReportDatesParams(dateMode, startDateType, startDateValue, endDateType, endDateValue, dateGrouping, timezone, relatedIds, relatedType) {
    let dateParams
    if (Object.hasOwn(this.dateModeFunctions, dateMode)) {
      dateParams = this.dateModeFunctions[dateMode](startDateType, startDateValue, endDateType, endDateValue)
    } else {
      console.error(`Report Date Mode Parsing Error: Unknown Date Mode Type ${dateMode}!`)
      throw new Error('Unknown Date Mode Type')
    }
    dateParams['time_grouping'] = dateGrouping
    dateParams['timezone'] = timezone
    dateParams['related_ids'] = relatedIds
    dateParams['related_type'] = relatedType
    return dateParams
  }

  static getReportDatesParamsFromDates(startDate, endDate, dateGrouping, timezone, relatedIds = null, relatedType) {
    let dateParams = this._datesToQuery(
      startDate,
      endDate
    )
    dateParams['time_grouping'] = dateGrouping
    dateParams['timezone'] = timezone
    if (relatedIds) {
      dateParams['related_ids'] = relatedIds
    }
    if (relatedType) {
      dateParams['related_type'] = relatedType
    }
    return dateParams
  }

  static offsetDateFromReportParams (dateMode, startDateValue, endDateValue, offset) {
    startDateValue = moment(startDateValue)
    endDateValue = moment(endDateValue)
    if (dateMode === 'between-dates') {
      let periodOffsetDays = (endDateValue.diff(startDateValue, 'days') + 1) * offset
      startDateValue = startDateValue.add(periodOffsetDays, 'days')
      endDateValue = endDateValue.add(periodOffsetDays, 'days')
    } else if (dateMode === 'last-week' || dateMode === 'wtd' || dateMode === 'last-seven') {
      let periodOffsetDays = 7 * offset
      startDateValue = startDateValue.add(periodOffsetDays, 'days')
      endDateValue = endDateValue.add(periodOffsetDays, 'days')
    } else if (dateMode === 'last-month' || dateMode === 'mtd') {
      startDateValue = startDateValue.add(offset, 'months')
      endDateValue = endDateValue.add(offset, 'months')
    } else if (dateMode === 'last-year' || dateMode === 'ytd') {
      startDateValue = startDateValue.add(offset, 'years').startOf('year')
      endDateValue = startDateValue.endOf('year')
    } else {
      // Day by day options like today, yesterday
      let periodOffsetDays = 1 * offset
      startDateValue = startDateValue.add(periodOffsetDays, 'days')
      endDateValue = endDateValue.add(periodOffsetDays, 'days')
    }
    return {
      startDate: startDateValue,
      endDate: endDateValue,
      dateMode: 'between-dates'
    }
  }

  static offsetDateFromReportParams(dateMode, startDateValue, endDateValue, offset) {
    startDateValue = moment(startDateValue)
    endDateValue = moment(endDateValue)
    if (dateMode === 'between-dates') {
      let periodOffsetDays = (endDateValue.diff(startDateValue, 'days') + 1) * offset
      startDateValue = startDateValue.add(periodOffsetDays, 'days')
      endDateValue = endDateValue.add(periodOffsetDays, 'days')
    } else if (dateMode === 'last-week' || dateMode === 'wtd' || dateMode === 'last-seven') {
      let periodOffsetDays = 7 * offset
      startDateValue = startDateValue.add(periodOffsetDays, 'days')
      endDateValue = endDateValue.add(periodOffsetDays, 'days')
    } else if (dateMode === 'last-month' || dateMode === 'mtd') {
      startDateValue = startDateValue.add(offset, 'months')
      endDateValue = endDateValue.add(offset, 'months')
    } else if (dateMode === 'last-year' || dateMode === 'ytd') {
      startDateValue = startDateValue.add(offset, 'years').startOf('year')
      endDateValue = startDateValue.endOf('year')
    } else {
      // Day by day options like today, yesterday
      let periodOffsetDays = 1 * offset
      startDateValue = startDateValue.add(periodOffsetDays, 'days')
      endDateValue = endDateValue.add(periodOffsetDays, 'days')
    }
    return {
      startDate: startDateValue,
      endDate: endDateValue,
      dateMode: 'between-dates'
    }
  }

  static generateHistogramPlotLiveData(
    rawData,
    fieldName,
    groupType, // 'numeric' or 'date'
    groupParam, // bin size (for numeric) OR one of ['minute','hour','day','week','month','year'] for date
    nullValue = null
  ) {
    /**
     * - For groupType = 'numeric':
     *    groupParam should be the bin size (number).
     *    The function computes min and max, partitions the data into bins,
     *    and returns the bin label (e.g. "0-9", "10-19", etc.) or just the bin center.
     *
     * - For groupType = 'date':
     *    groupParam must be one of ['minute','hour','day','week','month','year'].
     *    We assume fieldName points to a numeric timestamp (seconds since epoch).
     *    We use moment.js in local time, align to the startOf(groupParam),
     *    and tally how many rows fall in each time bucket.
     *
     * Returns:
     *   [
     *     {
     *       x: [...labels...],
     *       y: [...counts...],
     *       type: 'bar'
     *     }
     *   ]
     */
    console.log('STARTING HISTOGRAM PLOT - LIVE DATA')
    console.log(rawData)
    console.log(fieldName)
    console.log(groupType)
    console.log(groupParam)
    console.log(nullValue)
    // If rawData is empty or null, return an empty structure.
    if (!rawData || !rawData.length) {
      console.log('No data to plot', rawData)
      return [{
        x: [],
        y: [],
        type: 'bar'
      }]
    }

    if (groupType === 'numeric') {
      // 1) Gather all numeric values
      const numericValues = []
      rawData.forEach(row => {
        const val = ReportHelper.getNestedField(row, fieldName, nullValue)
        if (val !== nullValue && typeof val === 'number') {
          numericValues.push(val)
        }
      })

      if (numericValues.length === 0) {
        return [{
          x: [],
          y: [],
          type: 'bar'
        }]
      }

      // 2) Determine bin size, min, max
      const binSize = Number(groupParam) || 1
      const minVal = Math.min(...numericValues)
      const maxVal = Math.max(...numericValues)

      // 3) Create bin boundaries
      //    Example: if min=0, max=45, binSize=10 => 0-9,10-19,20-29,30-39,40-49
      //    We'll store counts in a map keyed by bin index
      const bins = {}
      const binCount = Math.ceil((maxVal - minVal) / binSize)

      for (let i = 0; i <= binCount; i++) {
        bins[i] = 0
      }

      // 4) Increment counts for each value
      numericValues.forEach(val => {
        const index = Math.floor((val - minVal) / binSize)
        bins[index]++
      })

      // 5) Build arrays for x (labels) and y (counts)
      const x = []
      const y = []

      Object.keys(bins).forEach(binIndexStr => {
        const binIndex = Number(binIndexStr)
        // Label can be a simple range "start-end" or the bin center
        const binStart = minVal + binIndex * binSize
        const binEnd   = binStart + binSize - 1
        // Example label: "0–9", "10–19", ...
        x.push(`${binStart}–${binEnd}`)
        y.push(bins[binIndex])
      })

      return [{
        x,
        y,
        type: 'bar'
      }]
    } else if (groupType === 'date') {
      // 1) Gather timestamps and convert them to the chosen moment-based interval
      //    We assume fieldName is a numeric field with seconds-since-epoch
      const bucketMap = {}

      rawData.forEach(row => {
        const timestamp = ReportHelper.getNestedField(row, fieldName, nullValue)
        if (timestamp !== nullValue && typeof timestamp === 'number') {
          // Convert to local time with moment
          const m = moment.unix(timestamp).local()
          // Align to the start of groupParam
          // groupParam in [minute, hour, day, week, month, year]
          if (['minute','hour','day','week','month','year'].includes(groupParam)) {
            m.startOf(groupParam)
            // Use a string label; you can adapt the format if you prefer
            // a different representation for each interval
            const label = m.format('YYYY-MM-DD HH:mm')
            // Count it in the map
            if (!bucketMap[label]) {
              bucketMap[label] = 0
            }
            bucketMap[label] += 1
          }
        }
      })

      // 2) Sort the buckets chronologically (the labels are date strings)
      const sortedLabels = Object.keys(bucketMap).sort((a, b) => {
        // parse both as moment
        const ma = moment(a, 'YYYY-MM-DD HH:mm')
        const mb = moment(b, 'YYYY-MM-DD HH:mm')
        return ma.valueOf() - mb.valueOf()
      })

      // 3) Build arrays for x (labels) and y (counts)
      const x = []
      const y = []
      sortedLabels.forEach(lbl => {
        x.push(lbl)
        y.push(bucketMap[lbl])
      })

      return [{
        x,
        y,
        type: 'bar'
      }]
    }

    //  If groupType is something else, return an empty trace or handle as needed
    return [{
      x: [],
      y: [],
      type: 'bar'
    }]
  }
  static getReportColor(index) {
    /***
     * Get a colour from the colour palette, accepting an infinite index length, by automatically looping through
     * the palette when we reach the end.
     * i.e. Get a color for a chart without having to check that there are colors left in the palette.
     */
    if ((index) < reportColorPalette.length) {
      return reportColorPalette[index]
    } else {
      return reportColorPalette[index % ReportHelper.reportColorPalette.length]
    }
  }

  static sumArray(array) {
    return array.reduce((a, b) => a + b, 0)
  }

  static arraymodeOperations = {
    SUM: this.sumProp,
    COUNT: this.countProp,
    'COUNT-TRUE': this.countIfTrue,
    'COUNT-NOT-NULL': this.countNotNull,
    'COUNT-DISTINCT': this.countDistinct,
    'COUNT-IF': this.countIfEqual
  }

  static sumProp(devices, propName, compareValue) {
    let deviceValues = devices.map(device => device[propName])
    return ReportHelper.sumArray(deviceValues)
  }

  static countProp(devices, propName, compareValue) {
    let deviceValues = devices.map(device => Object.hasOwn(device, propName))
    return ReportHelper.sumArray(deviceValues)
  }

  static countIfTrue(devices, propName, compareValue) {
    let deviceValues = devices.map(device => device[propName] === true)
    return ReportHelper.sumArray(deviceValues)
  }

  static countNotNull(devices, propName, compareValue) {
    let deviceValues = devices.map(device => device[propName] !== null && device[propName] !== undefined)
    return ReportHelper.sumArray(deviceValues)
  }

  static countDistinct(devices, propName, compareValue) {
    let deviceValues = [...new Set(devices.map(device => device[propName]))]
    return deviceValues.length
  }

  static countIfEqual(devices, propName, compareValue) {
    if (compareValue === undefined) {
      throw Error('No Aggregation compare value provided with aggregation_mode set to COUNT-IF')
    }
    let deviceValues = devices.map(device => device[propName] === compareValue)
    return deviceValues.length
  }

  /***
   * Execute a common array operation for a list of objects. 'mode' String determines which function will be called on
   * the array. Results a number based on the operation selected (i.e. COUNT, SUM, COUNT-IF, etc)
   * @param mode  String describing the mode, SUM, COUNT, COUNT-TRUE, COUNT-NOT-NULL, COUNT-DISTINCT, COUNT-IF
   * @param objects Array of objects to use.
   * @param propName  Prop name to use on each object.
   * @param compareValue  Required for COUNT-IF
   * @returns {*}
   */
  static getArrayOperatorResult(mode, objects, propName, compareValue) {
    if (Object.hasOwn(this.arraymodeOperations, mode)) {
      return this.arraymodeOperations[mode](objects, propName, compareValue)
    } else {
      throw Error('Unknown Aggregation Mode: ' + mode)
    }
  }

  static arraymodeOperationArray = {
    SUM: (objects, propName, compareValue) => objects,
    COUNT: (objects, propName, compareValue) => objects,
    'COUNT-TRUE': this.countIfTrueArray,
    'COUNT-NOT-NULL': this.countNotNullArray,
    'COUNT-DISTINCT': (objects, propName, compareValue) => objects, // This one sort of doesn't make sense.
    'COUNT-IF': this.countIfEqualArray
  }

  static countIfTrueArray(objects, propName, compareValue) {
    return objects.filter(device => device[propName] === true)
  }

  static countNotNullArray(objects, propName, compareValue) {
    return objects.filter(device => device[propName] !== null && device[propName] !== undefined)
  }

  static countIfEqualArray(objects, propName, compareValue) {
    if (compareValue === undefined) {
      throw Error('No Aggregation compare value provided with aggregation_mode set to COUNT-IF')
    }
    return objects.filter(device => device[propName] === compareValue)
  }

  /***
   * Complimentary functions for 'getArrayOperatorResult'. Returns the list of objects which were selected as part of
   * the getArrayOperatorResult function. This is to facilitate tooltips and labels on widgets that use the above
   * function. (e.g To display which devices make up the metric calculated using the getArrayOperatorResult function)
   * @param mode  String describing the mode, SUM, COUNT, COUNT-TRUE, COUNT-NOT-NULL, COUNT-DISTINCT, COUNT-IF
   * @param objects Array of objects to use.
   * @param propName  Prop name to use on each object.
   * @param compareValue  Required for COUNT-IF
   * @returns {*}
   */
  static getArrayOperatorArray(mode, objects, propName, compareValue) {
    if (Object.hasOwn(this.arraymodeOperationArray, mode)) {
      return this.arraymodeOperationArray[mode](objects, propName, compareValue)
    } else {
      throw Error('Unknown Aggregation Mode: ' + mode)
    }
  }

  static async convertToCSV(data, fields) {
    /* Convert the data to CSV format, using the fields provided. */
    console.log('Converting to CSV')
    console.log({data, fields})
    const refinedData = []
    // Add the header row
    let headerRow = []
    fields.forEach(field => {
      if (Object.hasOwn(field, 'skip_csv')) {
        if (field['skip_csv']) {
          return
        }
      }
      headerRow.push([field['label']])
    })
    refinedData.push(headerRow)
    // Add the data rows
    data.forEach(row => {
      let refinedRow = []
      fields.forEach(field => {
        if (Object.hasOwn(field, 'skip_csv')) {
          if (field['skip_csv']) {
            return
          }
        }

        if (Object.hasOwn(row, field['key'])) {
          if (Object.hasOwn(field, 'formatter')) {
            refinedRow.push(field['formatter'](row[field['key']]))
          } else {
            refinedRow.push(row[field['key']])
          }
        } else {
          refinedRow.push('?')
        }
      })
      refinedData.push(refinedRow)
    })
    // Convert to CSV
    let csvContent = ''
    refinedData.forEach(row => {
      csvContent += row.join(',') + '\n'
    })
    return csvContent
  }

  static missingReportParams (report) {
    /**
     * Returns a list of parameters that do not have a default value for the report.
     * @type {string[]}
     */
    let paramFields = [
      'default_date_mode',
      'default_date_grouping'
    ]

    if (report.data.report_options && report.data.report_options.device_selection_mode) {
      paramFields.push('device_selection_mode')
    }

    if (report.default_parameters.default_date_mode === 'between-dates') {
      paramFields.push('default_start_date_type')
      paramFields.push('default_start_date_value')
      paramFields.push('default_end_date_type')
      paramFields.push('default_end_date_value')
    }

    return paramFields.filter(field => report.default_parameters[field] === undefined)
  }

  static requiresParameterSelection (report) {
    /**
     * Returns true if the report requires the user to select parameters
     */
    return ReportHelper.missingReportParams(report).length > 0
  }

  static getReportDefaultQueryParams (report) {
    /**
     * Returns the default query parameters for a report
     * NOTE: This is only really useful if ALL of the default parameters are set (or at least enough to run the report)
     *
     * @type {string}
     */

    let currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    let relatedType = report.data.report_options ? report.data.report_options.related_type : 'device'

    return ReportHelper.getReportDatesParams(
      report.default_parameters.default_date_mode,
      report.default_parameters.start_date_type,
      report.default_parameters.start_date_value,
      report.default_parameters.default_end_date_type,
      report.default_parameters.default_end_date_value,
      report.default_parameters.default_date_grouping,
      currentTimezone,
      null, relatedType)
  }

  static getNestedField (dict, key, nullValue) {
    /**
     * Retrieve the key from the dict. However, if the key has a . in it, treat it as a nested field.
     */
    // If there's nothing to inspect, stop here.
    if (!dict) {
      return nullValue
    }

    if (key.includes('.')) {
      const [firstKey, ...rest] = key.split('.')
      return ReportHelper.getNestedField(dict[firstKey], rest.join('.'), nullValue)
    } else {
      return dict[key] == null ? nullValue : dict[key]
    }
  }
}
